
import React from 'react';

import {connect} from 'react-redux';



import './inicio.scss';






const Pinicio = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id65aaff07b0184704f908b0f5" >

      <div id="ia8u2" className="">
      
      <div id="i0jb" className="">
      
      <div id="iu9g" className="">
      
      <div id="ihnh" className="">
      
      <img
        className=""
        id="i1a37f"
        src="https://assetsprojects.s3.amazonaws.com/406racilrv413vd.png"
        alt="undefined"
      />
      
        <a
          target=""
          className=""
          id="it2n43"
          href="#"
        >
          
        </a>
        
      </div>
      
      <div id="i1ng" className="">
      
        <a
          target=""
          className="link-menu-mediredes "
          id="i5arf"
          href="#i7knd"
        >
          
    <div id="iyh4j" className="">
    <span>servicios</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-menu-mediredes "
          id="i4pal"
          href="#"
        >
          
    <div id="ibxbl" className="">
    <span>nosotros</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-menu-mediredes "
          id="i7fc1"
          href="#isxqak"
        >
          
    <div id="iion6" className="">
    <span>nuestro equipo</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-menu-mediredes "
          id="icfc4"
          href="#ikirv"
        >
          
    <div id="ierip" className="">
    <span>RSE</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="i4c3" className="">
      
        <a
          target=""
          className="link-menu-mediredes "
          id="istlh"
          href="https://plataforma.mediredes.com/"
        >
          
    <div id="ikx27" className="">
    <span>iniciar sesión</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="i1cux" className="">
      
      <div id="ipo2l" className="">
      
      <div id="iybe1" className="">
      
      <p className="" id="idln6">
        <span>EL PUNTO DE CONEXIÓN PARA EL PROFESIONAL DE LA SALUD</span>
      </p>
      
      <div id="ipyqg" className="">
      
        <a
          target=""
          className=""
          id="iu0wc"
          href="https://plataforma.mediredes.com/"
        >
          
    <div id="iuym9" className="">
    <span>Suscríbete</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="i45fa" className="">
      
      <img
        className=""
        id="i8tn9"
        src="https://assetsprojects.s3.amazonaws.com/406racilroyrotx.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="io5hf" className="">
      
      <div id="i7knd" className="">
      
      <p className="" id="ijcoh">
        <span>EVENTOS</span>
      </p>
      
      </div>
      
      <div id="ikr1s" className="">
      
      <div id="ih5hi" className="">
      
      <div id="iu8dz" className="item-button-mediredes ">
      
        <a
          target=""
          className=""
          id="ionvj"
          href="https://plataforma.mediredes.com/"
        >
          
    <div id="ihd2l" className="">
    <span>Ver eventos</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="i8h5k" className="item-button-mediredes ">
      
        <a
          target=""
          className=""
          id="itopm"
          href="#https://plataforma.mediredes.com/"
        >
          
    <div id="iijep" className="">
    <span>Crear eventos</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="iaxw1" className="item-button-mediredes ">
      
        <a
          target=""
          className=""
          id="igd7v"
          href="#https://plataforma.mediredes.com/"
        >
          
    <div id="im01c" className="">
    <span>suscríbete</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="ig85v" className="">
      
      <img
        className=""
        id="ib3va"
        src="https://assetsprojects.s3.amazonaws.com/406racilroz22j1.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i65nk" className="">
      
      <p className="" id="in688">
        <span>SOMOS UN ECOSISTEMA DIGITAL DE CONCETIVIDAD PARA PROFESIONALES MÉDICOS.</span>
      </p>
      
      <p className="" id="iezl1">
        <span>Conéctate en donde le encuentres en el momento que quieras con tus colegas, con la comunidad científica, laboratorios, eventos internacionales de tu interés y oportunidades para estudiantes de medicina</span>
      </p>
      
      <div id="ilv6t" className="">
      
          <div id="i0eayi" className="">
            <iframe
              title="Video Youtube"
              src="https://www.youtube.com/embed/OySttZu_d_k?"
              allowFullScreen="undefined"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          
      </div>
      
      </div>
      
      <div id="ihw9z" className="">
      
      <p className="" id="ikirv">
        <span>CON TUS POSTS LOGRAMOS AYUDAR ...</span>
      </p>
      
      <div id="ivbc6" className="">
      
      <div id="iqvjiu" className="">
      
      <img
        className=""
        id="itac3x"
        src="https://assetsprojects.s3.amazonaws.com/406racilsauzbrg.jpeg"
        alt="undefined"
      />
      
      </div>
      
        <a
          target=""
          className=""
          id="i590az"
          href="#"
        >
          
        <a
          target=""
          className=""
          id="iq8chx"
          href="https://plataforma.mediredes.com/"
        >
          
    <div id="iklx0i" className="">
    <span>Únete a nuestra comunidad!</span>
    </div>
    
        </a>
        
        </a>
        
      </div>
      
      </div>
      
      <div id="igvpy" className="">
      
      <p className="" id="isxqak">
        <span>MEDIREDES EN NUMBERS</span>
      </p>
      
      <div id="ibh6vi" className="">
      
      <img
        className=""
        id="isxwvv"
        src="https://assetsprojects.s3.amazonaws.com/406racilrpkqryk.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i4gt2h"
        src="https://assetsprojects.s3.amazonaws.com/406racilrpks1pi.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i8ouu8"
        src="https://assetsprojects.s3.amazonaws.com/406racilrpku8tk.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="iwrcfn" className="">
      
      <div id="i2tx9p" className="">
      
      <img
        className=""
        id="i59fiq"
        src="https://assetsprojects.s3.amazonaws.com/406racilrv413vd.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="idpmak">
        
      </p>
      
      <p className="" id="idetlv">
        <span>direccionmediredes@gmail.com</span>
      <br className="" id="ijbj5k" />
      <span>mediredescomunicacion@gmail.com</span>
      </p>
      
      <p className="" id="ildtt5">
        <span>Tel. 2220-0000</span>
      </p>
      
      <p className="" id="ilpy86">
        <span>Teg. Col. Edificio</span>
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pinicio);
  